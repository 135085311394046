import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './home'
import './home.css'

class App extends Component {
  render() {
    return <Home/>
  }
}

export default App;
